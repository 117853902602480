import React from 'react';
import PropTypes from 'prop-types';
import FormControl from 'react-bootstrap/lib/FormControl';
import ErrorBlock from './ErrorBlock';

class FormFieldWithoutLabel extends React.Component {
  render() {
    const {
      tag, inputClass, maxLength, onBlur, placeholder, errors, type,
      onChange, componentClass, value, className
    } = this.props;

    return (
      <div className={className} data-dtm={tag}>
        <FormControl
          ref={this.storeRefToField}
          className={inputClass}
          type={type}
          componentClass={componentClass}
          maxLength={maxLength}
          value={value || ''}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur || (() => { })}
          placeholder={placeholder}
        />
        {errors.length !== 0 ? <ErrorBlock errors={errors} /> : null}
      </div>
    );
  }
}

FormFieldWithoutLabel.propTypes = {
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  componentClass: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  inputClass: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  tag: PropTypes.string
};

export default FormFieldWithoutLabel;
