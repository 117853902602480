/* global navigator, Blob, document, window:true */
const linkFileProps = (name, dataUrl, isZip) => {
  const fileLink = document.createElement('a');

  if (fileLink.download == null) {
    if (window.open(dataUrl, '_blank')) {
      return;
    }
    /*
     This is the best that can be done for Safari and the likes,
     To get here the browser has to not support download, window.open or msSaveBlob
     Even Libraries like download.js and FileSaver.js can only do this.
     */
    window.location.href = dataUrl;
    return;
  }

  fileLink.style.display = 'none';
  fileLink.href = dataUrl;
  fileLink.download = isZip ? name : decodeURIComponent(name).split('+').join(' ');
  fileLink.target = '_blank';
  document.body.appendChild(fileLink);
  fileLink.click();
  document.body.removeChild(fileLink);
};

const downloadFile = ({ name, type, data }) => {
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(new Blob([data]), name);
    return;
  }
  const dataUrl = `data:${type};charset=utf-8,${encodeURI(data)}`;
  linkFileProps(name, dataUrl, false);
};

const downloadZipFile = ({ name, blob }) => {
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, name);
    return;
  }
  const dataUrl = window.URL.createObjectURL(blob);
  linkFileProps(name, dataUrl, true);
};

const downloadFileAsBlob = ({ name, data, type }) => {
  const blob = new Blob([data], { type });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, name);
    return;
  }
  const dataUrl = window.URL.createObjectURL(blob);
  linkFileProps(name, dataUrl, false);
};

const downloadExcelFile = (result) => {
  const element = document.createElement('a');
  element.setAttribute('href', result);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export default {
  downloadFile, downloadZipFile, downloadFileAsBlob, downloadExcelFile
};
