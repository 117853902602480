import moment from 'moment';
import 'moment/locale/de-at';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/ru';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/ja';
import 'moment/locale/zh-cn';
import 'moment/locale/zh-hk';
import 'moment/locale/ko';
import 'moment/locale/pt-br';
import 'moment/locale/en-gb';// strange...locale seems to default to last imported locale if
// specified locale does not match...thus putting en-gb last here...


// TODO: NG RP 2017-06-26: Fix issue with moment.
// There is currently an issue whereby if the provided date- time is invalid in the client's
// timezone moment will "make the time valid" by adding an hour which can make the start or end time
// wrong.

const japaneseOrChineseLocales = ['ja', 'zh-cn', 'zh-hk'];
const isJapaneseOrChineseLocale = () => japaneseOrChineseLocales.includes(moment.locale());

export function formatTime(dateString) {
  return moment(dateString).format('HH:mm');
}

export function formatMediumDate(dateString) {
  return isJapaneseOrChineseLocale()
    ? moment(dateString).format('MMM Do')
    : moment(dateString).format('Do MMM');
}

export function formatDayName(dateString) {
  return moment(dateString).format('dddd');
}

export function formatLongDate(dateString) {
  return moment(dateString).format('YYYY-MM-DD');
}

export function toMoment(dateString) {
  return moment(dateString);
}

export function setMomentLocale(locale) {
  moment.locale(locale);
}

export function formatDayNameAndDate(date) {
  return isJapaneseOrChineseLocale()
    ? `${formatMediumDate(date)} ${formatDayName(date)}`
    : `${formatDayName(date)}, ${formatMediumDate(date)}`;
}

export function formatDateForLocale(dateString, format, locale) {
  return moment(dateString).locale(locale || 'en-GB').format(format);
}

