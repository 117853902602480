import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/lib/Button';
import classnames from 'classnames';
import Classes from '../../../common/Classes';
import { IconButton } from '../../../common/form';

const editButton = (isNotEditable, editButtonText, onEditClick, translations, tag) => {
  if (isNotEditable) {
    return '';
  }

  if (editButtonText) {
    return (
      <Button
        bsStyle={Classes.primary}
        className={Classes.pullRight}
        onClick={onEditClick}
        data-dtm={tag}
      >
        {editButtonText}
      </Button>
    );
  }

  return (
    <IconButton
      ariaLabel={translations.buttons.edit}
      onClick={onEditClick}
      className={Classes.secondary01Button}
      iconClass={classnames(Classes.icons.edit, Classes.icons.large)}
      tag={tag}
    />
  );
};

const SectionViewMode = ({
  translations,
  onEditClick,
  children,
  isNotEditable,
  editButtonText,
  tags,
  isPrimary,
  className
}) => (
  <section
    className={
      classnames(
Classes.formViewModeSection,
        { [Classes.primarySection]: isPrimary },
        className
)}
  >
    <div className={Classes.textRight}>
      {editButton(isNotEditable, editButtonText, onEditClick, translations, tags.edit)}
    </div>
    {children}
  </section>
);

SectionViewMode.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.any
  ]),
  isNotEditable: PropTypes.bool,
  editButtonText: PropTypes.string,
  isPrimary: PropTypes.bool,
  tags: PropTypes.shape({
    edit: PropTypes.string
  }),
  className: PropTypes.string
};

export default SectionViewMode;
