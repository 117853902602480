import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Classes from '../common/Classes';
import StringFormatter from '../common/StringFormatter';

const EventEditionListItem = function EventEditionListItem(props) {
  const {
    name, dashHref, eventEditionId, primaryLocale, startDate, translations, eventEditionSettingsHref, isAtlasShow
  } = props;
  const dashHrefProcessed = `${dashHref}`
    .replace('{0}', eventEditionId)
    .replace('{1}', primaryLocale);

  return (
    <div>
      {
        isAtlasShow ?
        (<>
          <a href={dashHrefProcessed}>{name} - {startDate}</a>,
          <a
            href={StringFormatter.format(eventEditionSettingsHref, eventEditionId, primaryLocale)}
            target="_blank"
            title={translations.iconSettingsTooltip}
          >
            <i className={classnames(Classes.icons.settings, Classes.icons.small)} />
          </a>
        </>) :
        (<>
          <span>{name} - {startDate}</span>,
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="non-atlas-warning-tooltip" className="warning-tooltip">
                {translations.nonAtlasWarningTooltip}
              </Tooltip>
            }
          >
            <i className={classnames(Classes.icons.warningTriangle, Classes.icons.small, Classes.textDanger)} />
          </OverlayTrigger>
        </>)
      }
    </div>
  );
};

EventEditionListItem.propTypes = {
  name: PropTypes.string.isRequired,
  dashHref: PropTypes.string.isRequired,
  eventEditionId: PropTypes.string.isRequired,
  primaryLocale: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  translations: PropTypes.object.isRequired,
  eventEditionSettingsHref: PropTypes.string.isRequired,
  isAtlasShow: PropTypes.bool
};

export default EventEditionListItem;
