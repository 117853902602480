import React from 'react';
import { memo, useEffect, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import EventEditionListItem from './EventEditionListItem';
import EventEditionListService from './api/EventEditionListService';
import TreeView from '../common/TreeView';
import TranslationService from '../translations';

function getEventEditionName(eventEdition) {
  const eventEditionItem = eventEdition.multilingual.find(
    (multi) => multi.locale === eventEdition.primaryLocale
  );
  if (eventEditionItem) {
    return eventEditionItem.name;
  }
  return eventEdition.multilingual[0].name;
}

function TreeContent({ baseApiUrl, context, eventId, label, navigation }) {
  const [shouldLoad, setShouldload] = useState(false);
  const [items, setItems] = useState([]);
  const isMounted = useRef(false);
  const translations = TranslationService.getTranslations(context.interfaceLocale);
  const handleClick = useCallback(() => setShouldload(true), []);

  useEffect(() => {
    isMounted.current = true;
    if (shouldLoad) {
      EventEditionListService.getEventEditionsByEventId(
        baseApiUrl,
        context.interfaceLocale,
        eventId
      ).then((data) => {
        if (Array.isArray(data?.eventEditions) && isMounted.current) {
          setItems(data.eventEditions);
        }
      });
    }
    return () => {
      isMounted.current = false;
    };
  }, [shouldLoad]);

  return (
    <TreeView defaultcollapsed={true} nodeLabel={label} onClick={handleClick}>
      {items.map((eventEdition) => {
        const eventEditionName = getEventEditionName(eventEdition);
        const newDate = new Date(eventEdition.startDate).toLocaleDateString(
          context.interfaceLocale
        );
        return (
          <EventEditionListItem
            dashHref={navigation.showDetailsUrl}
            eventEditionId={eventEdition.externalId}
            eventEditionSettingsHref={navigation.eventEditionSettingsUrl}
            isAtlasShow={eventEdition.digitalPlatform === 'Atlas'}
            key={eventEdition.id}
            name={eventEditionName}
            primaryLocale={eventEdition.primaryLocale}
            startDate={newDate}
            translations={translations.showList.eventEdition}
          />
        );
      })}
    </TreeView>
  );
}

TreeContent.propTypes = {
  baseApiUrl: PropTypes.string.isRequired,
  context: PropTypes.shape({
    interfaceLocale: PropTypes.string.isRequired,
  }).isRequired,
  eventId: PropTypes.string.isRequired,
  label: PropTypes.object.isRequired,
  navigation: PropTypes.shape({
    showDetailsUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default memo(TreeContent);
