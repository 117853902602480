import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/lib/Button';
import classnames from 'classnames';
import Classes from '../Classes';

const IconButton = ({
  ariaLabel,
  onClick,
  disabled,
  secondary,
  iconClass,
  tag,
  className
}) => (
  <Button
    className={classnames(Classes.iconButton, className)}
    bsClass={Classes.button}
    bsStyle={secondary ? null : Classes.primary}
    onClick={onClick}
    aria-label={ariaLabel}
    title={ariaLabel}
    disabled={disabled}
    data-dtm={tag}
  >
    <i className={iconClass} />
  </Button>
);

IconButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  iconClass: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  className: PropTypes.string
};

export default IconButton;
