import React from 'react';
import PropTypes from 'prop-types';
import Well from 'react-bootstrap/lib/Well';
import Button from 'react-bootstrap/lib/Button';
import Classes from '../../../common/Classes';
import ErrorContainer from '../../../common/components/ErrorContainer';
import InlineLoginError from '../../../common/components/InlineLoginError';

const SectionEditMode = props => (
  <Well className={props.className}>
    <InlineLoginError
      mustLogIn={props.mustLogIn}
      translations={props.translations}
      tags={props.tags}
      navigation={props.navigation}
      isRxAdmin={props.isRxAdmin}
    />
    <ErrorContainer errors={props.errors} />
    {props.children}
    <div className={Classes.textRight}>
      <Button
        onClick={props.onCancelClick}
        className={Classes.secondary02Button}
        data-dtm={props.tags.cancel}
      >
        {props.translations.cancel}
      </Button>
      <Button
        onClick={props.onSaveClick}
        disabled={props.isSaving}
        bsStyle={Classes.primary}
        data-dtm={props.tags.save}
      >
        {props.translations.save}
      </Button>
    </div>
  </Well>
);

SectionEditMode.propTypes = {
  onCancelClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  translations: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.any
  ]),
  tags: PropTypes.shape({
    save: PropTypes.string,
    cancel: PropTypes.string,
    followLoginUrl: PropTypes.string,
    loginLink: PropTypes.string
  }),
  navigation: PropTypes.object.isRequired,
  mustLogIn: PropTypes.bool,
  isRxAdmin: PropTypes.bool,
  className: PropTypes.string
};

export default SectionEditMode;
