import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/lib/Alert';
import Classes from '../Classes';

const SuccessContainer = ({ messages }) => (
  <div>
    {messages.map(m => <Alert key={m} bsStyle={Classes.success}>{m}</Alert>)}
  </div>
);

SuccessContainer.propTypes = {
  messages: PropTypes.array.isRequired
};

export default SuccessContainer;
