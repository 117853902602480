import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import Classes from '../Classes';
import Switch from './Switch';

const FormFieldSwitch = props => (
  <FormGroup className={props.className} controlId={props.id}>
    <ControlLabel className={Classes.h4}>{props.label}</ControlLabel>
    {props.helpText ? <HelpBlock>{props.helpText}</HelpBlock> : null}
    <Switch
      onChange={props.onChange}
      activeText={props.activeText}
      inactiveText={props.inactiveText}
      tag={props.tag}
      value={props.value}
    />
  </FormGroup>
);

FormFieldSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
  value: PropTypes.bool,
  className: PropTypes.string,
  activeText: PropTypes.string.isRequired,
  inactiveText: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired
};

export default FormFieldSwitch;
