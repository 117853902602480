import React from 'react';
import PropTypes from 'prop-types';
import Classes from '../../common/Classes';

const ErrorBlock = props => (
  <div>
    {props.errors.map(error =>
      <div key={error} className={Classes.textDanger}>{error}</div>)}
  </div>
);

ErrorBlock.propTypes = {
  errors: PropTypes.array.isRequired
};

export default ErrorBlock;
