import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import ErrorBlock from './ErrorBlock';
import Classes from '../../common/Classes';

const getOptions = options => options.map(option => {
        return (<option
            value={option.code}
            key={option.code}
        >
            {option.name}
        </option>)
    }
);


const FormFieldDropdown = (props) => {
  const getClassNames = (className, errors) => {
    const hasErrorClass = errors.length !== 0 ? Classes.hasError : '';
    return classnames([className, hasErrorClass]);
  };

    return (
        <FormGroup
            controlId={props.id}
            className={getClassNames(props.className, props.errors)}
            data-dtm={props.tag}
        >
            {props.label ? <ControlLabel className={Classes.h4}>{props.label}</ControlLabel> : null}
            {props.helpText ? <HelpBlock>{props.helpText}</HelpBlock> : null}
            <FormControl
                componentClass={props.type}
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                disabled={props.isDisabled ? props.isDisabled : false}
            >
                {props.hideBlank
                    ? null
                    : <option value="" key="blank">{props.placeholderText || ' '}</option>}
                {getOptions(props.options)}
            </FormControl>
            {props.errors.length !== 0 ? <ErrorBlock key={props.id} errors={props.errors}/> : null}
        </FormGroup>);
};

FormFieldDropdown.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    tag: PropTypes.string,
    errors: PropTypes.array.isRequired,
    placeholderText: PropTypes.string,
    hideBlank: PropTypes.bool,
    helpText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    isDisabled: PropTypes.bool,
};

export default FormFieldDropdown;

