import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HelpBlock from '../../common/components/HelpBlock';
import Classes from '../../common/Classes';
import Utils from '../../common/Utils';
import { FormField, Switch } from '../../common/form';
import ButtonDropdown from '../../common/components/ButtonDropdown';
import { Locale, EventCycle, EventType, Top100Show, BusinessRelationship, ShowSettingsFieldErrorSelectors } from '../../common/Constants';
import featureToggles from '../../common/featureToggle/featureToggles';

const ShowSettingsEditMode = ({
  translations, fieldErrors, onUpdateField, multilingual,
  isGbsRegistrationEnabled, isGbsSalesEnabled, isParticipationEvent, isMatchmakingEvent,
  rXEventCode, eventCycle, eventType, top100, businessRelationship, isJointVenture, isAnonymousRegistrationEnabled
}) => {
  const [draftLocales, setDraftLocales] = useState([]);
  const [cycle, setEventCycle] = useState();
  const [type, setEventType] = useState();
  const [top, setTop100] = useState();
  const [brs, setBusinessRelationship] = useState();

  const localeOptions = Locale.filter(localeOption => Utils.findByLocale(multilingual, localeOption.code) == null);

  const onRemoveLocale = (removedLocale) => {
    setDraftLocales(draftLocales.filter(draftLocale => draftLocale !== removedLocale));
    onUpdateField({
      multilingual: multilingual.filter(currentLocale => currentLocale.locale !== removedLocale)
    });
  };

  const onAddLocale = (newLocale) => {
    setDraftLocales(draftLocales.concat(newLocale));
    onUpdateField({
      multilingual: multilingual.concat({
        locale: newLocale,
        name: ''
      })
    });
  };

  const getEventCycleOptions = (eventCycle) => { return EventCycle.filter(options => options.code != eventCycle); }
  const eventCycleOptions = getEventCycleOptions(eventCycle);
  const onChangeEventCycle = newEventCycle => {
    setEventCycle(newEventCycle);
    onUpdateField({
        eventCycle: newEventCycle
    });
  };

  const getEventTypeOptions = (eventType) => { return EventType.filter(options => options.code != eventType); }
  const eventTypeOptions = getEventTypeOptions(eventType);
  const onChangeEventType = newEventType => {
  setEventType(newEventType);
  onUpdateField({
      eventType: newEventType
    });
  };

  const getTop100Options = (top100) => { return Top100Show.filter(options => options.code != top100); }
  const top100Options = getTop100Options(top100);
  const onChangeTop100 = newTop100 => {
    setTop100(newTop100);
  onUpdateField({
      top100: newTop100
    });
  };

  const getBusinessRelationshipOptions = (businessRelationship) => { return BusinessRelationship.filter(options => options.code != businessRelationship); }
  const businessRelationshipOptions = getBusinessRelationshipOptions(businessRelationship);
  const onChangeBusinessRelationship = newbusinessRelationship => {
    setBusinessRelationship(newbusinessRelationship);
  onUpdateField({
    businessRelationship: newbusinessRelationship
    });
  };

  return (
    <div>
      <HelpBlock>
        { translations.mandatoryIndicator }
      </HelpBlock>
      {
        multilingual.map(lang =>
          (<FormField
            key={lang.locale}
            id={lang.locale}
            type="text"
            value={lang.name}
            label={`${translations.nameLocaleLabel} ${lang.locale}*`}
            errors={Utils.filterFieldErrors(fieldErrors, lang.locale)}
            onChange={(value) => {
              onUpdateField({
                multilingual: multilingual.map(lingual => (lingual.locale === lang.locale ?
                  {
                    locale: lang.locale,
                    name: value
                  } : lingual))
              });
            }}
            removeTitle={translations.removeLocaleButtonLabel}
            onRemove={draftLocales.some(draftLocale => draftLocale === lang.locale) ? onRemoveLocale : null}
            tag=""
          />))
      }
      {
      featureToggles.isFeatureEnabled(featureToggles.Keys.IS_MODAL_UPDATE_DESCRIPTION_ENABLED)?
      <HelpBlock>
        { translations.localeDescription }
        <br /><br />
        { translations.localeNote }
      </HelpBlock>:null
      }
      <ButtonDropdown
        id="add_new_locale"
        title={translations.addLocaleButtonLabel}
        options={localeOptions}
        disabled={localeOptions.length === 0}
        onSelect={onAddLocale}
      />
      <FormField
        id="rX_event_code"
        type="text"
        label={translations.rXEventCodeLabel}
        value={rXEventCode}
        onChange={(value) => {
          onUpdateField({
            rXEventCode: value.trim() === '' ? null : value.trim()
          })
        }}
        errors={Utils.filterFieldErrors(fieldErrors, ShowSettingsFieldErrorSelectors.rXEventCode)}
      />
      <FormField
        id="event_cycle"
        type="text"
        readOnly={true}
        label={`${translations.eventCycleLabel}*`}
        value={eventCycle}
        onChange={(value) => {
          onUpdateField({
            eventCycle: value
          })
        }}
        errors={[]}
      />
      <ButtonDropdown
        id="change_eventCycle"
        title={translations.changeEventCycleButtonLabel}
        options={eventCycleOptions}
        noCaret
        disabled={eventCycleOptions.length === 0}
        onSelect={onChangeEventCycle}
      />
      <FormField
        id="event_type"
        type="text"
        readOnly={true}
        label={`${translations.eventTypeLabel}*`}
        value={eventType}
        onChange={(value) => {
          onUpdateField({
            eventType: value
          })
        }}
        errors={[]}
      />
      <ButtonDropdown
        id="change_eventType"
        title={translations.changeEventTypeButtonLabel}
        options={eventTypeOptions}
        noCaret
        disabled={eventTypeOptions.length === 0}
        onSelect={onChangeEventType}
      />
      <FormField
        id="top_100"
        type="text"
        readOnly={true}
        label={`${translations.top100Label}*`}
        value={top100}
        onChange={(value) => {
          onUpdateField({
            top100: value
          })
        }}
        errors={[]}
      />
      <ButtonDropdown
        id="change_top100"
        title={translations.changeTop100ButtonLabel}
        options={top100Options}
        noCaret
        disabled={top100Options.length === 0}
        onSelect={onChangeTop100}
      />
      <FormField
        id="business_relationship"
        type="text"
        readOnly={true}
        label={`${translations.businessRelationshipLabel}*`}
        value={businessRelationship}
        onChange={(value) => {
          onUpdateField({
            businessRelationship: value
          })
        }}
        errors={[]}
      />
      <ButtonDropdown
        id="change_businessRelationship"
        title={translations.changeBusinessRelationshipButtonLabel}
        options={businessRelationshipOptions}
        noCaret
        disabled={businessRelationshipOptions.length === 0}
        onSelect={onChangeBusinessRelationship}
      />
      {
        featureToggles.isFeatureEnabled(featureToggles.Keys.IS_MODAL_UPDATE_DESCRIPTION_ENABLED)?
        <HelpBlock>
        { translations.flagsDescription }
        <br /> <br />
        { translations.flagsNote }
        </HelpBlock>:null
      }
      <div className={Classes.topMargin}>
        <Switch
          description={translations.gbsRegistrationFlagDescription}
          value={isGbsRegistrationEnabled}
          onChange={(hasGbsRegistrationEnabled) => {
              onUpdateField({
                isGbsRegistrationEnabled: hasGbsRegistrationEnabled
              });
          }}
          tag=""
          isSmall
        />
        <Switch
          description={translations.gbsSalesFlagDescription}
          value={isGbsSalesEnabled}
          onChange={(hasGbsSalesEnabled) => {
              onUpdateField({
                isGbsSalesEnabled: hasGbsSalesEnabled
              });
          }}
          tag=""
          isSmall
        />
        <Switch
          description={translations.participationFlagDescription}
          value={isParticipationEvent}
          onChange={(hasParticipationEvent) => {
              onUpdateField({
                isParticipationEvent: hasParticipationEvent
              });
          }}
          tag=""
          isSmall
        />
        <Switch
          description={translations.matchmakingFlagDescription}
          value={isMatchmakingEvent}
          onChange={(hasMatchmakingEvent) => {
              onUpdateField({
                isMatchmakingEvent: hasMatchmakingEvent
              });
          }}
          tag=""
          isSmall
        />
        <Switch
          description={translations.isAnonymousRegistrationEnabledFlagDescription}
          value={isAnonymousRegistrationEnabled}
          onChange={(value) => {
              onUpdateField({
                isAnonymousRegistrationEnabled: value
              });
          }}
          tag=""
          isSmall
        />
        <Switch
          description={translations.isJointVentureFlagDescription}
          value={isJointVenture}
          onChange={(value) => {
              onUpdateField({
                isJointVenture: value
              });
          }}
          tag=""
          isSmall
        />
      </div>
    </div>
  );
};

ShowSettingsEditMode.propTypes = {
  translations: PropTypes.shape({
    nameLocaleLabel: PropTypes.string.isRequired,
    localeDescription: PropTypes.string.isRequired,
    localeNote: PropTypes.string.isRequired,
    rXEventCodeLabel: PropTypes.string,
    eventCycleLabel: PropTypes.string,
    eventTypeLabel: PropTypes.string,
    top100Label: PropTypes.string,
    businessRelationshipLabel: PropTypes.string,
    flagsDescription: PropTypes.string.isRequired,
    flagsNote: PropTypes.string.isRequired,
    gbsRegistrationFlagDescription: PropTypes.string.isRequired,
    gbsSalesFlagDescription: PropTypes.string.isRequired,
    participationFlagDescription: PropTypes.string.isRequired,
    matchmakingFlagDescription: PropTypes.string.isRequired,
    isJointVentureFlagDescription: PropTypes.string.isRequired,
    isAnonymousRegistrationEnabledFlagDescription: PropTypes.string.isRequired,
    mandatoryIndicator: PropTypes.string.isRequired,
    removeLocaleButtonLabel: PropTypes.string.isRequired,
    addLocaleButtonLabel: PropTypes.string.isRequired,
    changeEventCycleButtonLabel: PropTypes.string,
    changeEventTypeButtonLabel: PropTypes.string,
    changeTop100ButtonLabel: PropTypes.string,
    changeBusinessRelationshipButtonLabel:PropTypes.string,
  }).isRequired,
  fieldErrors: PropTypes.object.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  multilingual: PropTypes.array.isRequired,
  isGbsRegistrationEnabled: PropTypes.bool,
  isGbsSalesEnabled: PropTypes.bool,
  isParticipationEvent: PropTypes.bool,
  isMatchmakingEvent: PropTypes.bool,
  rXEventCode: PropTypes.string,
  eventCycle: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
  top100: PropTypes.string.isRequired,
  businessRelationship: PropTypes.string.isRequired,
  isJointVenture: PropTypes.bool,
  isAnonymousRegistrationEnabled: PropTypes.bool
};

export default ShowSettingsEditMode;
