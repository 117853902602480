import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Classes from '../Classes';

const HelpBlock = ({
  className, tag, children
}) => (
  <div className={classnames(Classes.HelpSection, className)} dtm-tags={tag}>
    {children}
  </div>
);

HelpBlock.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.string,
  className: PropTypes.string
};

export default HelpBlock;
