import React from 'react';
import PropTypes from 'prop-types';
import Classes from '../Classes';

class Switch extends React.Component {
  constructor(props) {
    super(props);
    this.toggleInnovativeRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.isFocused) {
      this.toggleInnovativeRef.current.click();
    }
  }

  render() {
    const {
      tag, value, onChange, isSmall, activeText, inactiveText, description, isDisabled, customClasses
    } = this.props;

    const classNames = {
      true: {
        switch: Classes.switchSmall,
        slidingPanel: Classes.slidingPanelSmall,
        slider: Classes.sliderSmall
      },
      false: {
        switch: Classes.switch,
        slidingPanel: Classes.slidingPanel,
        slider: Classes.slider
      }
    }[!!isSmall];

    return (
      <div className={customClasses}>
        <label className={classNames.switch} data-dtm={tag}>
          <input
            ref={this.toggleInnovativeRef}
            className={Classes.switchCheckbox}
            type="checkbox"
            checked={value}
            disabled={!onChange || (isDisabled !== undefined && isDisabled)}
            onChange={e => onChange(e.target.checked)}
          />
          <span className={classNames.slidingPanel}>
            <span className={Classes.switchInner}>
              <span className={Classes.switchInnerBefore}>{activeText}</span>
              <span className={Classes.switchInnerAfter}>{inactiveText}</span>
            </span>
            <span className={classNames.slider} />
          </span>
        </label>
        <span className={Classes.switchLabel}>{description}</span>
      </div>
    );
  }
}

Switch.propTypes = {
  onChange: PropTypes.func,
  activeText: PropTypes.string,
  inactiveText: PropTypes.string,
  description: PropTypes.string,
  customClasses: PropTypes.string,
  tag: PropTypes.string.isRequired,
  value: PropTypes.bool,
  isSmall: PropTypes.bool,
  isFocused: PropTypes.bool,
  isDisabled: PropTypes.bool
};

export default Switch;
