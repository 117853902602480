import features from '../../../featureToggle.json';
import Utils from '../Utils';

const isFeatureEnabled = (name) => {
  // we need global to get out of webpack bundle on server side
  const featureToggleQueryParam = 'featuretoggle=false';
  if (global.window && Utils.getLocationQueryParam()
    .toLowerCase().includes(featureToggleQueryParam)) {
    return false;
  }

  const settings = global.window ? (global.window.reactSettings || global.window.reactSettingsExhibitorHub ||
    global.window.reactSettingsProductsDirectory || global.window.reactSettingsProductDetails
    || global.window.reactSettingsArtistDetails) : global.process.env;

  if (settings && settings.environment) {
    return features[settings.environment] != null && features[settings.environment][name] === true;
  }
  return false;
};

const Keys = {
  LIMIT_FILTER_ITEMS: 'limitFilterItems',
  SHOW_THIRD_PARTY_SERVICES: 'showThirdPartyServices',
  DISPLAY_MULTI_LOGINS: 'multiLogins',
  EMPERIA_ONBOARDING: 'emperiaOnboarding',
  SHARERS_ON_DETAILS: 'sharersOnDetails',
  EXHIBITOR_INSIGHTS: 'exhibitorInsight',
  PPS_MULTILINGUAL: 'ppsMultilingual',
  SORT_PRODUCTS_BY_LAST_UPDATED: 'sortProductsByLastUpdated',
  LOGGEDINADMIN_HUB_DTMTAG: 'loggedInAdminHubDtmTag',
  EXHIBITOR_PRODUCTS_QUESTIONS_FROM_PPS: 'exhibitorProductsQuestionsFromPPS',
  VALIDATE_EXHIBITOR_PRODUCTS_QUESTIONS_FROM_PPS: 'validateExhibitorProductsQuestionsFromPPS',
  ADDSTANDBYMSH: 'Addstandsbymsh',
  VALIDATE_ENTITLEMENT_FAILURE: 'ValidateEntitlementFailure',
  REGBOX_DEPENDENCY_REMOVAL: 'RegBoxDependencyRemoval',
  KOREAN_TRANSLATION: 'KoreanTranslation',
  JAPANESE_TRANSLATION: 'JapaneseTranslation',
  SPANISH_TRANSLATION: 'SpanishTranslation',
  LEADS_TIMELINE: 'LeadsTimeline',
  SHOW_NAME_ON_ADMIN_CREATION: 'showNameOnAdminCreation',
  CREATE_LEAD_FOR_EXHIBITORS: 'createLeadForExhibitors',
  IS_AB_TESTING_ENABLED: 'isAbTestingEnabled',
  IS_MODAL_UPDATE_DESCRIPTION_ENABLED: 'isModalUpdateDescriptionEnabled',
  IS_AUTOFILL_IN_REQUEST_QUOTE_FORM_ENABLED: 'isAutoFillInRequestQuoteFormEnabled',
  LEAD_DETECTOR_PRICE_SURVEY_POPUP: 'LeadDetectorPriceSurveyPopup',
  COMPANY_INSIGHTS_PANEL: 'CompanyInsightsPanel'
};

export default {isFeatureEnabled, Keys};
