import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/lib/Button';
import classnames from 'classnames';
import Classes from '../Classes';

const DownloadExhibitorButton = ({
  className, titleWhenDownloading, title, onClick, isDownloading, tag
}) => {
  const titleToUse = isDownloading ? titleWhenDownloading : title;
  return (
    <span className={isDownloading ? Classes.cursorNotAllowed : ''}>
      <Button
        className={classnames(className, Classes.exhibitorDownloadButton)}
        bsStyle={Classes.default}
        onClick={onClick}
        disabled={isDownloading}
        data-dtm={tag}
      >
        <span>{titleToUse}</span>
        <i
          className={classnames(
            Classes.icons.downArrow,
            Classes.icons.iconSpaceLeft,
            isDownloading ? Classes.hide : ''
          )}
        />
      </Button>
    </span>
  );
};

DownloadExhibitorButton.propTypes = {
  title: PropTypes.string.isRequired,
  titleWhenDownloading: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  isDownloading: PropTypes.bool,
  tag: PropTypes.string.isRequired
};

export default DownloadExhibitorButton;
