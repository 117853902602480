import React from 'react';
import PropTypes from 'prop-types';
import Classes from '../../common/Classes';
import { FormFieldViewMode, Switch } from '../../common/form';

const ShowSettingsViewMode = ({
  translations, onEmptyFieldClick, multilingual,
  isGbsRegistrationEnabled, isGbsSalesEnabled, isParticipationEvent, isMatchmakingEvent,
  rXEventCode, eventCycle, eventType, top100, businessRelationship, isJointVenture, isAnonymousRegistrationEnabled
}) => (
  <div>
    {
      multilingual.map(lang =>
        (<FormFieldViewMode
          key={lang.locale}
          title={`${translations.nameLocaleLabel} ${lang.locale}`}
          content={lang.name}
          onEmptyClick={onEmptyFieldClick}
        />))
    }
    <FormFieldViewMode
      title={translations.rXEventCodeLabel}
      content={rXEventCode}
      onEmptyClick={onEmptyFieldClick}
    />
    <FormFieldViewMode
      title={translations.eventCycleLabel}
      content={eventCycle}
      onEmptyClick={onEmptyFieldClick}
    />
    <FormFieldViewMode
      title={translations.eventTypeLabel}
      content={eventType}
      onEmptyClick={onEmptyFieldClick}
    />
    <FormFieldViewMode
      title={translations.top100Label}
      content={top100}
      onEmptyClick={onEmptyFieldClick}
    />
    <FormFieldViewMode
      title={translations.businessRelationshipLabel}
      content={businessRelationship}
      onEmptyClick={onEmptyFieldClick}
    />
    <div className={Classes.nonClickable}>
      <Switch
        description={translations.isGbsRegistrationEnabledLabel}
        tag=""
        value={isGbsRegistrationEnabled}
        isSmall
      />
      <Switch
        description={translations.isGbsSalesEnabledLabel}
        tag=""
        value={isGbsSalesEnabled}
        isSmall
      />
      <Switch
        description={translations.isParticipationEventLabel}
        tag=""
        value={isParticipationEvent}
        isSmall
      />
      <Switch
        description={translations.isMatchmakingEventLabel}
        tag=""
        value={isMatchmakingEvent}
        isSmall
      />
      <Switch
        description={translations.isAnonymousRegistrationEnabledLabel}
        tag=""
        value={isAnonymousRegistrationEnabled}
        isSmall
      />
      <Switch
        description={translations.isJointVentureLabel}
        tag=""
        value={isJointVenture}
        isSmall
      />
    </div>
  </div>
);

ShowSettingsViewMode.propTypes = {
  translations: PropTypes.object.isRequired,
  onEmptyFieldClick: PropTypes.func.isRequired,
  multilingual: PropTypes.array.isRequired,
  isGbsRegistrationEnabled: PropTypes.bool,
  isGbsSalesEnabled: PropTypes.bool,
  isParticipationEvent: PropTypes.bool,
  isMatchmakingEvent: PropTypes.bool,
  rXEventCode: PropTypes.string,
  eventCycle: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
  top100: PropTypes.string.isRequired,
  businessRelationship: PropTypes.string.isRequired,
  isJointVenture: PropTypes.bool,
  isAnonymousRegistrationEnabled: PropTypes.bool
};

export default ShowSettingsViewMode;
