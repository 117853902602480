import React from 'react';
import PropTypes from 'prop-types';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import classnames from 'classnames';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import Classes from '../Classes';
import ErrorBlock from './ErrorBlock';

class FormFieldDropdownTreeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.previousErrors = JSON.stringify(props.errors);
    this.placeholderText = props.placeholder;
    this.options = props.options;
  }

  componentDidMount() {
    if (this.props.isFocused) {
      const container = this.fieldElement;
      container.searchInput.focus();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.placeholderText !== nextProps.placeholder) {
      this.placeholderText = nextProps.placeholder;
      return true;
    }
    if (this.previousErrors !== JSON.stringify(nextProps.errors)) {
      this.previousErrors = JSON.stringify(nextProps.errors);
      return true;
    }
    if (this.options !== nextProps.options) {
      this.options = nextProps.options;
      return true;
    }
    return false;
  }

  addHasErrorClass = errors => (errors.length !== 0 ? Classes.hasError : '');

  storeRefToField = (elem) => {
    this.fieldElement = elem;
  };

  render() {
    return (
      <FormGroup
        className={classnames(this.props.className, Classes.formGroupMultiSelect, this.addHasErrorClass(this.props.errors))}
        controlId={this.props.id}
        data-dtm={this.props.tag}
      >
        {this.props.label ?
          <ControlLabel className={Classes.h4}>{this.props.label}</ControlLabel>
          : null}
        {this.props.helpText ?
          <HelpBlock>{this.props.helpText}</HelpBlock>
          : null}
        <DropdownTreeSelect
          ref={this.storeRefToField}
          texts={{placeholder: this.props.placeholder}}
          data={this.props.options}
          onChange={this.props.onChange}
          noMatchesText={this.props.noResultsText}
          clearable={false}
          className={classnames(`id-${this.props.id}`, this.addHasErrorClass(this.props.errors), this.props.className)}
          radioSelect={this.props.isMultiSelect}
          // set unique className value because DropdownTreeSelect does some internal processing based on className
        />
        {this.props.errors.length !== 0 ? <ErrorBlock errors={this.props.errors} /> : null}
      </FormGroup>
    );
  }
}

FormFieldDropdownTreeSelect.propTypes = {
  id: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  label: PropTypes.string,
  noResultsText: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isFocused: PropTypes.bool,
  className: PropTypes.string,
  errors: PropTypes.array.isRequired,
  isMultiSelect: PropTypes.bool
};

export default FormFieldDropdownTreeSelect;

