import RoyalboxService from './RoyalboxService';
import EventMapperHelper from '../../eventEditionList/helpers/EventMapperHelper';
import BusinessUnitFilterHelper from '../helpers/BusinessUnitFilterHelper';
import TranslationService from '../../translations';
import ServiceResultFactory from '../../common/api/ServiceResultFactory';
import Request from '../../common/api/request';

const getEventEditionList = (baseUrl, locale) => {
  const baseApiUrl = baseUrl.endsWith('/')
    ? baseUrl.substring(0, baseUrl.length - 1)
    : baseUrl;
  const eventResult = RoyalboxService.getEvents(baseApiUrl, locale);
  const eventEditionResult = RoyalboxService.getEventEditions(baseApiUrl, locale);
  return Promise.all([eventResult, eventEditionResult])
    .then(valArray => EventMapperHelper.mapEvents(valArray[0], valArray[1]));
};

const getEvents = (baseUrl, locale) => {
  const baseApiUrl = baseUrl.endsWith('/')
    ? baseUrl.substring(0, baseUrl.length - 1)
    : baseUrl;
  return RoyalboxService.getEvents(baseApiUrl, locale);
};

const getEventEditionsByEventId = (baseUrl, locale, eventId) => {
  const baseApiUrl = baseUrl.endsWith('/')
    ? baseUrl.substring(0, baseUrl.length - 1)
    : baseUrl;
  return RoyalboxService.getEventEditionsByEventId(baseApiUrl, locale, eventId);
};

const exportList = (baseApiUrl, headingLocale) => {
  const url = `${baseApiUrl}v1/event-editions-exports`;
  return Request
    .getFile(url)
    .then(result => ServiceResultFactory.fromSuccess({ file: result.file }))
    .catch(result => ServiceResultFactory.fromError(headingLocale, result, false));
};

const getBusinessUnits = (baseUrl, locale) => {
  const baseApiUrl = baseUrl.endsWith('/')
    ? baseUrl.substring(0, baseUrl.length - 1)
    : baseUrl;
  return RoyalboxService.getBusinessUnits(baseApiUrl, locale)
    .then(response => Object.values(response).map(bu => ({ code: bu.id, name: bu.name })));
};

const getUiData = (baseUrl, locale) =>
  Promise.all([getEvents(baseUrl, locale), getBusinessUnits(baseUrl, locale)])
    .then((valArray) => {
      const filteredItems = BusinessUnitFilterHelper.filterBusinessUnits(valArray[0].events, valArray[1]);
      const translations = TranslationService.getTranslations(locale);
      if (valArray[1].length > 1) {
        filteredItems.unshift({ code: 'all', name: translations.showList.allBusinessUnits });
      }
      return Object.assign({
        ...valArray[0], ...{ events: valArray[0].events }, ...{ errors: valArray[0].errors }, ...{ businessUnits: filteredItems }
      });
    });

export default {
  getEventEditionList, getEvents, getEventEditionsByEventId, getBusinessUnits, getUiData, exportList
};
