import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import DatePicker from 'react-datepicker';
import classnames from 'classnames';
import Classes from '../Classes';
import ErrorBlock from './ErrorBlock';
import { toMoment, formatLongDate } from '../dateHelpers';


const showError = (errors, displayErrorOverride) =>
  (displayErrorOverride || errors.length !== 0 ? Classes.hasError : '');

const FormFieldDatePicker = ({
  onChange,
  interfaceLocale,
  errors,
  id,
  label,
  helpText,
  value,
  tag,
  displayErrorOverride,
  placeholder,
  className
}) => (
  <FormGroup
    className={classnames(showError(errors, displayErrorOverride), className)}
    controlId={id}
    data-dtm={tag}
  >
    <ControlLabel className={Classes.h4}>{label}</ControlLabel>
    {helpText ? <HelpBlock>{helpText}</HelpBlock> : null}
    <DatePicker
      selected={value ? toMoment(value).toDate() : null}
      onChange={(e) => (e ? onChange(formatLongDate(e)) : onChange(e))}
      placeholderText={placeholder}
      showMonthDropdown
      showYearDropdown
      onMonthChange={false}
      onYearChange={false}
      dropdownMode="select"
      locale={interfaceLocale}
      id={id}
      dateFormat="dd-MM-yyyy"
    />
    {errors.length !== 0 ? <ErrorBlock errors={errors} /> : null}
  </FormGroup>
);

FormFieldDatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  interfaceLocale: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  value: PropTypes.string,
  tag: PropTypes.string,
  displayErrorOverride: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string
};

export default FormFieldDatePicker;
