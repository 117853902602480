import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Classes from '../Classes';
import Utils from '../Utils';
import mixpanelConstants from "../mixpanelConstants";
import {trackMixpanelEvent} from "../MixpanelHelper"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const IconLink = ({
  ariaLabel, content, href, iconClass, tag, exhibitorId, openInNewTab, className, packageId, mixpanelProps, tracker, isBTestingEnabled
}) => (
  <a
    className={className}
    href={href}
    aria-label={ariaLabel}
    title={ariaLabel}
    data-dtm={tag}
    data-dtm-attributes={JSON.stringify({ exhibitorId, package: Utils.getPackageName(packageId), isBTestingEnabled })}
    target={openInNewTab ? '_blank' : undefined}
    rel="noopener noreferrer nofollow"
    onClick={() => trackMixpanelEvent(mixpanelConstants.eventName.exhibitorContactInfo, mixpanelProps, tracker)}
  >
    <FontAwesomeIcon icon={iconClass} />
    <span>{content}</span>
  </a>
);

IconLink.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  exhibitorId: PropTypes.string.isRequired,
  packageId: PropTypes.number,
  href: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  openInNewTab: PropTypes.bool,
  className: PropTypes.string,
  mixpanelProps: PropTypes.object,
  tracker: PropTypes.object,
  isBTestingEnabled: PropTypes.bool
};

export default IconLink;
