import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import TranslationService from '../translations';
import EventEditionListService from './api/EventEditionListService';
import ErrorContainer from '../common/components/ErrorContainer';
import CircleSpinner from '../common/components/CircleSpinner';
import Classes from '../common/Classes';
import FormFieldDropdown from '../common/form/FormFieldDropdown';
import DownloadConsolidateReportButton from '../common/components/DownloadExhibitorButton';
import PageLoginError from '../common/components/PageLoginError';
import Tags from '../common/dtmTags';
import Exporter from '../common/files/Exporter';
import AuthorizationStore from '../common/api/authorizationStore';
import ModalShowSettings from './ModalShowSettings';
import TreeContent from './TreeContent';

let allEvents;

class EventEditionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      errors: [],
      isLoading: true,
      mustLogIn: false,
      businessUnits: [],
      isDownloadingCosolidatedReport: false,
      isRxAdmin: false,
      currentEventIdSettings: null
    };
  }

  componentDidMount() {
    const { context, baseApiUrl } = this.props;
    EventEditionListService.getUiData(baseApiUrl, context.interfaceLocale)
      .then(this.updateStateFromService);

    this.isRxAdmin();
  }

  onDownloadConsolidatedReport = () => {
    const { baseApiUrl } = this.props;
    this.setState({ isDownloadingCosolidatedReport: true });
    EventEditionListService
      .exportList(baseApiUrl)
      .then(({ file, errors, mustLogIn }) => {
        if (file) {
          Exporter.downloadFileAsBlob(file);
        }
        this.setState({
          isDownloadingCosolidatedReport: false,
          errors,
          mustLogIn
        });
      });
  };

  onShowSettingsSaved = (data, event) => {
    // event name was changed in event settings modal => update the event name in the tree view
    if (data.multilingual[0].name !== event.multilingualDto[0].name) {
      const updatedEvents = this.state.events.map(ev =>
        (
          ev.id === event.id ? {
            ...ev,
            multilingualDto: data.multilingual
          } : ev
        ));

      this.setState({ events: updatedEvents });
    }
  }

  getDownloadConsolidatedReportButton = translations =>
    (this.state.isRxAdmin ?
      <DownloadConsolidateReportButton
        className={classnames(Classes.downloadExhibitorList, Classes.pullRight)}
        titleWhenDownloading={translations.showList.processing}
        title={translations.showList.consolidatedReport}
        onClick={this.onDownloadConsolidatedReport}
        isDownloading={this.state.isDownloadingCosolidatedReport}
        tag={Tags.eventEditionList.consolidatedReport}
      /> : null)

  checkRole = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const role = JSON.parse(window.atob(base64)).role || [];
    return role.includes('urn:rxrole:rxadmin');
  };

  isRxAdmin = () => {
    AuthorizationStore.getToken()
      .then((token) => {
        if (token === null || token === undefined) {
          return this.setState({ isRxAdmin: false });
        }
        return this.setState({ isRxAdmin: this.checkRole(token) });
      })
      .catch(() => this.setState({ isRxAdmin: false }));
  };

  updateStateFromService = (serviceResult) => {
    allEvents = serviceResult.events;
    this.setState({
      events: serviceResult.events,
      errors: serviceResult.errors,
      isLoading: false,
      businessUnits: serviceResult.businessUnits
    });
  };

  filterEvents = (code) => {
    const filteredResult = code === 'all' ? allEvents : allEvents.filter(x => x.businessUnitId === code);

    this.setState({
      events: filteredResult
    });
  };

  render() {
    const { context, navigation } = this.props;
    const { mustLogIn, errors } = this.state;
    const translations = TranslationService.getTranslations(context.interfaceLocale);

    if (mustLogIn) {
      return (
        <PageLoginError
          translations={translations}
          navigation={this.props.navigation}
          tags={Tags.eventEditionList}
        />
      );
    }

    if (errors.length !== 0) {
      return <ErrorContainer errors={errors} />;
    }

    if (this.state.isLoading) {
      return (
        <span
          className={classnames(
            Classes.circleSpinner.spinnerWrapper,
            Classes.circleSpinner.spinnerWrapperBig,
            Classes.circleSpinner.spinnerWrapperFixed
          )}
        >
          <CircleSpinner />
        </span>
      );
    }

    return (
      <div>
        <h1>{translations.showList.title}</h1>
        <div>
          <p className={Classes.inlineBlock} >{translations.showList.intro}</p>
          {this.getDownloadConsolidatedReportButton(translations)}
        </div>
        <FormFieldDropdown
          id="buSelection"
          label=""
          type="select"
          onChange={(code) => {
            this.filterEvents(code);
          }}
          options={this.state.businessUnits}
          value={this.state.selectedBusinessUnitId}
          hideBlank
          errors={[]}
          tag={Tags.eventEditionList.businessUnitSelect}
        />
        <div>
          {this.state.events.map((event) => {
            const eventName = event.multilingualDto[0].name;
            const showEventSettingsForCurrentEvent = this.state.currentEventIdSettings === event.id;
            const style = showEventSettingsForCurrentEvent ? { backgroundColor: 'yellow' } : undefined;

            const label = (
              <span className="node" style={style}>
                {eventName}
                <FontAwesomeIcon
                  className={classnames(Classes.textPrimary, Classes.icons.small)}
                  icon={faCog}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ currentEventIdSettings: event.id });
                  }}
                  title={translations.showList.showSettings.showSettingsTooltip}
                />
              </span>
            );
            return (
              <React.Fragment>
                <TreeContent
                  label={label}
                  eventId={event.id}
                  baseApiUrl={this.props.baseApiUrl}
                  context={this.props.context}
                  navigation={this.props.navigation}
                />
                <ModalShowSettings
                  eventId={event.id}
                  eventName={eventName}
                  show={showEventSettingsForCurrentEvent}
                  onHide={() => {
                    this.setState({ currentEventIdSettings: null });
                  }}
                  onSaved={(data) => {
                    this.onShowSettingsSaved(data, event);
                  }}
                  translations={translations.showList.showSettings}
                  baseApiUrl={this.props.baseApiUrl}
                  interfaceLocale={context.interfaceLocale}
                  isRxAdmin={this.state.isRxAdmin}
                  navigation={navigation}
                  tags={Tags.eventEditionList.showSettings}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }
}

EventEditionList.propTypes = {
  navigation: PropTypes.shape({
    showDetailsUrl: PropTypes.string.isRequired
  }).isRequired,
  baseApiUrl: PropTypes.string.isRequired,
  context: PropTypes.shape({
    interfaceLocale: PropTypes.string.isRequired
  }).isRequired
};

export default EventEditionList;
