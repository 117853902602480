import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import FormControl from 'react-bootstrap/lib/FormControl';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import Button from 'react-bootstrap/lib/Button';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import classnames from 'classnames';
import Classes from '../../common/Classes';
import ErrorBlock from './ErrorBlock';
import CharacterLimit from './CharacterLimit';

const FormField = (props) => {
  const getClassNames = (className, errors) => {
    const hasErrorClass = errors.length !== 0 ? Classes.hasError : '';
    return classnames([className, hasErrorClass]);
  };

  const formControl = (
    <FormControl
      className={props.inputClass}
      type={props.type}
      componentClass={props.componentClass}
      maxLength={props.maxLength}
      value={props.value || ''}
      onChange={e => props.onChange(e.target.value)}
      onBlur={props.onBlur || (() => { })}
      placeholder={props.placeholder}
      focus={props.isFocused ? this : undefined}
      readOnly={props.readOnly ? props.readOnly: false}
    />
  );

  const formInput = props.onRemove ? (
    <InputGroup>
      {formControl}
      <InputGroup.Button>
        <Button
          id={props.id}
          bsStyle={Classes.danger}
          bsClass={Classes.button}
          onClick={e => props.onRemove(e.currentTarget.id, props.value)}
          title={props.removeButtonTitle}
          aria-label={props.removeButtonTitle}
        >
          <i className={Classes.icons.remove} />
        </Button>
      </InputGroup.Button>
    </InputGroup>
  ) : formControl;

  return (
    <FormGroup
      className={getClassNames(props.className, props.errors)}
      controlId={props.id}
      data-dtm={props.tag}
    >
      {props.label ?
        <ControlLabel
          className={classnames(Classes.h4, Classes.wrapWord, props.labelClass)}
        >
          {props.label}
        </ControlLabel>
        : null}
      {props.helpText ? <HelpBlock>{props.helpText}</HelpBlock> : null}
      {formInput}
      {props.characterLimit ? <CharacterLimit>{props.characterLimit}</CharacterLimit> : null}
      {props.errors.length !== 0 ? <ErrorBlock errors={props.errors} /> : null}
    </FormGroup>
  );
};

FormField.propTypes = {
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  componentClass: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helpText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  characterLimit: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  className: PropTypes.string,
  isFocused: PropTypes.bool,
  onBlur: PropTypes.func,
  onRemove: PropTypes.func,
  removeButtonTitle: PropTypes.string,
  tag: PropTypes.string,
  readOnly: PropTypes.bool
};

export default FormField;
