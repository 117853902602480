import React from 'react';
import PropTypes from 'prop-types';
import SectionViewMode from './SectionViewMode';
import SectionEditMode from './SectionEditMode';

const Section = (props) => {
  const tags = props.tags || {};

  if (props.isEditing) {
    return (
      <SectionEditMode
        translations={props.translations}
        onSaveClick={props.onSaveClick}
        isSaving={props.isSaving}
        onCancelClick={props.onCancelClick}
        errors={props.errors}
        isPrimary={props.isPrimary}
        tags={tags}
        mustLogIn={props.mustLogIn}
        isRxAdmin={props.isRxAdmin}
        navigation={props.navigation}
        className={props.className}
      >
        {props.children}
      </SectionEditMode>
    );
  }

  return (
    <SectionViewMode
      translations={props.translations}
      onEditClick={props.onEditClick}
      isNotEditable={props.isNotEditable}
      editButtonText={props.editButtonText}
      isPrimary={props.isPrimary}
      tags={tags}
      className={props.className}
    >
      {props.children}
    </SectionViewMode>
  );
};

Section.propTypes = {
  onSaveClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  isSaving: PropTypes.bool,
  isEditing: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.any
  ]),
  translations: PropTypes.object.isRequired,
  errors: PropTypes.array.isRequired,
  isNotEditable: PropTypes.bool,
  editButtonText: PropTypes.string,
  tags: PropTypes.shape({
    edit: PropTypes.string.isRequired,
    save: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    loginLink: PropTypes.string.isRequired
  }).isRequired,
  isPrimary: PropTypes.bool,
  navigation: PropTypes.object.isRequired,
  mustLogIn: PropTypes.bool,
  isRxAdmin: PropTypes.bool,
  className: PropTypes.string
};

export default Section;
