import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/lib/Alert';
import Classes from '../Classes';
import StringFormatter from '../StringFormatter';
import LoginUrlCreator from '../LoginUrlCreator';

const InlineLoginError = ({
  translations, tags, navigation, isRxAdmin, mustLogIn
}) => {
  const message = StringFormatter.formatToArray(
    translations.errorMessages.inlineLoginError,
    [
      <a
        href={LoginUrlCreator.createLoginUrl(navigation, isRxAdmin)}
        data-dtm={tags.loginLink}
        key={tags.loginLink}
        target="_blank"
      >
        <strong>{translations.errorMessages.inlineLoginErrorLink}</strong>
      </a>
    ]
  );
  return (
    <div>
      {
        mustLogIn ?
          <Alert bsStyle={Classes.danger}>{message}</Alert>
          : null
      }
    </div>
  );
};

InlineLoginError.propTypes = {
  translations: PropTypes.shape({
    errorMessages: PropTypes.shape({
      inlineLoginError: PropTypes.string.isRequired,
      inlineLoginErrorLink: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  navigation: PropTypes.shape({
    exhibitorHomePageUrl: PropTypes.string.isRequired,
    rxAdminHomePageUrl: PropTypes.string.isRequired
  }).isRequired,
  tags: PropTypes.shape({
    loginLink: PropTypes.string.isRequired
  }).isRequired,
  isRxAdmin: PropTypes.bool.isRequired,
  mustLogIn: PropTypes.bool
};

export default InlineLoginError;
