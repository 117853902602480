import React from 'react';
import Classes from '../Classes';

const CircleSpinner = () => (
  <div className={Classes.circleSpinner.container}>
    <div className={Classes.circleSpinner.wrapper}>
      <div className={`${Classes.circleSpinner.c1} ${Classes.circleSpinner.circle}`} />
      <div className={`${Classes.circleSpinner.c2} ${Classes.circleSpinner.circle}`} />
      <div className={`${Classes.circleSpinner.c3} ${Classes.circleSpinner.circle}`} />
      <div className={`${Classes.circleSpinner.c4} ${Classes.circleSpinner.circle}`} />
      <div className={`${Classes.circleSpinner.c5} ${Classes.circleSpinner.circle}`} />
      <div className={`${Classes.circleSpinner.c6} ${Classes.circleSpinner.circle}`} />
      <div className={`${Classes.circleSpinner.c7} ${Classes.circleSpinner.circle}`} />
      <div className={`${Classes.circleSpinner.c8} ${Classes.circleSpinner.circle}`} />
      <div className={`${Classes.circleSpinner.c9} ${Classes.circleSpinner.circle}`} />
      <div className={`${Classes.circleSpinner.c10} ${Classes.circleSpinner.circle}`} />
      <div className={`${Classes.circleSpinner.c11} ${Classes.circleSpinner.circle}`} />
      <div className={`${Classes.circleSpinner.c12} ${Classes.circleSpinner.circle}`} />
    </div>
  </div>
);

export default CircleSpinner;
