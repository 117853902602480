import React from 'react';
import PropTypes from 'prop-types';
import Section from '../../profile/exhibitorPage/section/Section';
import ShowSettingsEditMode from './ShowSettingsEditMode';
import ShowSettingsViewMode from './ShowSettingsViewMode';
import Classes from '../../common/Classes';

const ShowSettingsSection = props => (
  <Section
    translations={props.translations}
    errors={props.errors}
    isEditing={props.isEditing}
    onEditClick={props.onEditClick}
    isSaving={props.isSaving}
    onSaveClick={props.onSaveClick}
    onCancelClick={props.onCancelClick}
    navigation={props.navigation}
    tags={props.tags}
    isRxAdmin={props.isRxAdmin}
    mustLogIn={props.mustLogIn}
    className={Classes.rightColumnSection}
  >
    {
        props.isEditing ?
          <ShowSettingsEditMode
            translations={props.translations}
            fieldErrors={props.fieldErrors}
            onUpdateField={props.onUpdateField}
            multilingual={props.multilingual}
            isGbsRegistrationEnabled={props.isGbsRegistrationEnabled}
            isGbsSalesEnabled={props.isGbsSalesEnabled}
            isParticipationEvent={props.isParticipationEvent}
            isMatchmakingEvent={props.isMatchmakingEvent}
            rXEventCode={props.rXEventCode}
            eventCycle={props.eventCycle}
            eventType={props.eventType}
            top100={props.top100}
            businessRelationship={props.businessRelationship}
            isJointVenture={props.isJointVenture}
            isAnonymousRegistrationEnabled={props.isAnonymousRegistrationEnabled}
          />
        :
          <ShowSettingsViewMode
            translations={props.translations}
            onEmptyFieldClick={props.onEditClick}
            multilingual={props.multilingual}
            isGbsRegistrationEnabled={props.isGbsRegistrationEnabled}
            isGbsSalesEnabled={props.isGbsSalesEnabled}
            isParticipationEvent={props.isParticipationEvent}
            isMatchmakingEvent={props.isMatchmakingEvent}
            rXEventCode={props.rXEventCode}
            eventCycle={props.eventCycle}
            eventType={props.eventType}
            top100={props.top100}
            businessRelationship={props.businessRelationship}
            isJointVenture={props.isJointVenture}
            isAnonymousRegistrationEnabled={props.isAnonymousRegistrationEnabled}
          />
    }
  </Section>
);

ShowSettingsSection.propTypes = {
  multilingual: PropTypes.array,
  isGbsRegistrationEnabled: PropTypes.bool,
  isGbsSalesEnabled: PropTypes.bool,
  isParticipationEvent: PropTypes.bool,
  isMatchmakingEvent: PropTypes.bool,
  rXEventCode: PropTypes.string,
  eventCycle: PropTypes.string,
  eventType: PropTypes.string,
  top100: PropTypes.string,
  businessRelationship: PropTypes.string,
  isJointVenture: PropTypes.bool,
  isAnonymousRegistrationEnabled: PropTypes.bool,
  translations: PropTypes.shape({
    nameLocaleLabel: PropTypes.string,
    isGbsRegistrationEnabledLabel: PropTypes.string,
    isGbsSalesEnabledLabel: PropTypes.string,
    isParticipationEventLabel: PropTypes.string,
    isMatchmakingEventLabel: PropTypes.string,
    rXEventCodeLabel: PropTypes.string,
    eventCycleLabel: PropTypes.string,
    eventTypeLabel: PropTypes.string,
    top100Label: PropTypes.string,
    businessRelationshipLabel: PropTypes.string,
    isJointVentureLabel: PropTypes.string,
    isAnonymousRegistrationEnabledLabel: PropTypes.string,
    mandatoryIndicator: PropTypes.string.isRequired,
    removeLocaleButtonLabel: PropTypes.string,
    addLocaleButtonLabel: PropTypes.string,
    changeEventCycleButtonLabel: PropTypes.string,
    changeEventTypeButtonLabel: PropTypes.string,
    changeTop100ButtonLabel: PropTypes.string,
    changeBusinessRelationshipButtonLabel:PropTypes.string
  }),
  isEditing: PropTypes.bool,
  onEditClick: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  onSaveClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired,
  isRxAdmin: PropTypes.bool,
  mustLogIn: PropTypes.bool
};

export default ShowSettingsSection;
