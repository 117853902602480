/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/lib/Alert';
import Classes from '../Classes';
import StringFormatter from '../StringFormatter';
import Utils from '../Utils';

const PageLoginError = ({ translations, tags }) => {
  const message = StringFormatter.formatToArray(
    translations.errorMessages.pageLoginError,
    [
      <a
        href=""
        data-dtm={tags.followLoginLink}
        key={tags.followLoginLink}
        onClick={e => Utils.reloadLocation(e)}
      >
        <strong>{translations.errorMessages.pageLoginErrorLink}</strong>
      </a>
    ]
  );
  return (
    <div>
      <Alert bsStyle={Classes.danger}>{message}</Alert>
    </div>
  );
};

PageLoginError.propTypes = {
  translations: PropTypes.shape({
    errorMessages: PropTypes.shape({
      pageLoginError: PropTypes.string.isRequired,
      pageLoginErrorLink: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  tags: PropTypes.shape({
    followLoginLink: PropTypes.string.isRequired
  }).isRequired
};

export default PageLoginError;
