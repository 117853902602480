import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import Classes from '../Classes';

const FormFieldRadio = ({
  onChange, label, helpText, options, value, tag, className
}) => (
  <FormGroup className={className}>
    <h4 className={Classes.inlineBlock} >{label}</h4>
    {helpText ? <HelpBlock>{helpText}</HelpBlock> : null}
    <ButtonGroup
      data-dtm={tag}
    >
      {options.map(([key, text]) => (
        <Button
          bsStyle="primary"
          key={key}
          className={key === value ? 'active' : null}
          onClick={() => onChange(key)}
        >
          {text}
        </Button>
      ))}
    </ButtonGroup>
  </FormGroup>
);

FormFieldRadio.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  helpText: PropTypes.node,
  options: PropTypes.array.isRequired,
  value: PropTypes.number.isRequired,
  tag: PropTypes.string,
  className: PropTypes.string
};

export default FormFieldRadio;
