import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import Classes from '../Classes';
import Switch from '../form/Switch';

const FormFieldSwitchGroup = ({
  className, label, options, onChange, isNew, onIsNewChange
}) => (
  <FormGroup className={className}>
    <h4 className={Classes.inlineBlock}>{label}</h4>
    {
      isNew?
        <div key={isNew.tag}>
          <Switch
            onChange={isChecked => onIsNewChange(isChecked)}
            description={isNew.description}
            tag={isNew.tag}
            value={isNew.value}
            isSmall
          />
          <HelpBlock>{isNew.helpText}</HelpBlock>
      </div>:null
    }
 
    {options.map(option => (
      <div key={option.extraCharacteristicId}>
        <Switch
          onChange={isChecked => onChange(isChecked, option.extraCharacteristicId)}
          description={option.description}
          tag={option.tag}
          value={option.value}
          isSmall
        />
        <HelpBlock>{option.helpText}</HelpBlock>
      </div>
    ))}
  </FormGroup>
);

FormFieldSwitchGroup.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isNew: PropTypes.shape({
    description: PropTypes.string,
    value: PropTypes.bool,
    tag: PropTypes.string,
    helpText: PropTypes.string
  }),
  onIsNewChange: PropTypes.func
};

export default FormFieldSwitchGroup;
