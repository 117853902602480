const mapEvents = (eventResult, eventEditionResult) => {
  const mappedResult = [];
  eventResult.events.forEach((e) => {
    const eventItem = e;
    const eventEditionsList = eventEditionResult.eventEditions.filter(ee => ee.eventId === eventItem.id);
    eventItem.eventEditionsList = eventEditionsList.slice();
    mappedResult.push(eventItem);
  });

  const allErrors = eventResult.errors.concat(eventEditionResult.errors);

  return Object.assign({ ...eventResult, ...{ events: mappedResult }, ...{ errors: allErrors } });
};

export default { mapEvents };
