import Request from '../../common/api/request';
import ServiceResultFactory from '../../common/api/ServiceResultFactory';


const getEventById = (baseApiUrl, eventId, returnFields, interfaceLocale) => {
  const query = `{
      event(id: "${eventId}")
      { 
        ${returnFields}
      } 
    }`;

  return Request.publicGraphqlRequest(`${baseApiUrl}/graphql`, query)
    .then(response => ServiceResultFactory.fromGraphqlSuccess(response, interfaceLocale, 'event'))
    .catch(error => ServiceResultFactory.fromError(interfaceLocale, error));
};

const setEvent = (baseApiUrl, eventId, eventData, returnFields, interfaceLocale) => {
  const mutation = `mutation ($eventId: String!, $event: EventInputType!) {
      updateEvent(eventId: $eventId, event: $event)
      {
        ${returnFields}
      }
    }`;

  const variables = {
    eventId,
    event: eventData
  };

  return Request.graphqlRequest(`${baseApiUrl}/graphql`, mutation, variables)
    .then(response => ServiceResultFactory.fromGraphqlSuccess(response, interfaceLocale, 'updateEvent'))
    .catch(result => ServiceResultFactory.fromError(interfaceLocale, result, true));
};

export default { getEventById, setEvent };
