import React from 'react';
import PropTypes from 'prop-types';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import Classes from '../../common/Classes';

const CharacterLimit = ({ children }) => (
  <HelpBlock className={Classes.textRight}>
    {children}
  </HelpBlock>
);

CharacterLimit.propTypes = {
  children: PropTypes.string.isRequired
};

export default CharacterLimit;
