import React from 'react';
import PropTypes from 'prop-types';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import classnames from 'classnames';
import Classes from '../Classes';

const FormFieldReadOnlyMode = ({
  title, helpText, content, placeholder, className
}) => (
  <div className={classnames([Classes.formGroupViewMode, className])}>
    <h4>{title}</h4>
    {helpText ? <HelpBlock>{helpText}</HelpBlock> : null}
    <span>{content || placeholder}</span>
  </div>
);

FormFieldReadOnlyMode.propTypes = {
  title: PropTypes.string,
  helpText: PropTypes.node,
  content: PropTypes.node,
  placeholder: PropTypes.string,
  className: PropTypes.string
};

export default FormFieldReadOnlyMode;
