import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import HelpBlock from '../common/components/HelpBlock';
import Modal from 'react-bootstrap-latest/Modal';
import EventService from '../common/api/EventService';
import SuccessContainer from '../common/components/SuccessContainer';
import StringFormatter from '../common/StringFormatter';
import ErrorContainer from '../common/components/ErrorContainer';
import BigCircleSpinner from '../common/components/BigCircleSpinner';
import ShowSettingsSection from './ShowSettings/ShowSettingsSection';
import Utils from '../common/Utils';
import '../../styles/show-settings.less';
import featureToggles from '../common/featureToggle/featureToggles';

const eventFields = `
  id
  multilingual{
    locale
    name
  }
  eventGbsCode
  businessUnitId
  supportedInPlatform
  isGbsRegistrationEnabled
  isGbsSalesEnabled
  isParticipationEvent
  isMatchmakingEvent
  rXEventCode
  eventCycle
  eventType
  top100
  businessRelationship
  isJointVenture
  isAnonymousRegistrationEnabled
  `;

const ModalShowSettings = (props) => {
  const {
    eventId,
    eventName,
    baseApiUrl,
    translations,
    interfaceLocale,
    show
  } = props;

  const [event, setEvent] = useState({});
  const [status, setStatus] = useState({
    isLoading: true,
    isEditing: false,
    isSaving: false,
    savedSucceeded: false
  });

  const fetchEvent = async () => {
    const eventResponse = await EventService.getEventById(baseApiUrl, eventId, eventFields, interfaceLocale);

    setEvent(eventResponse);
    setStatus({
      isLoading: false,
      isEditing: false,
      isSaving: false
    });
  };

  useEffect(() => {
    if (show) {
      fetchEvent();
    }
  }, [eventId, show]);

  const onCancelClick = () => {
    setStatus({
      isLoading: true
    });
    fetchEvent();
  };

  const isFormDataValid = (data) => {
    const fieldErrors = {};

    data.multilingual.forEach((multilingual) => {
      if (!multilingual.name) {
        fieldErrors[multilingual.locale] = [StringFormatter.format(
          translations.errorMessages.multilingual_missing_field,
          [multilingual.locale]
        )];
      }
    });

    if(!Utils.isValidRxEventCode(data.rXEventCode))
      fieldErrors.rXEventCode = [translations.errorMessages.invalidRXEventCode]

    if (Object.keys(fieldErrors).length !== 0) {
      setEvent({
        ...event,
        fieldErrors
      });

      return false;
    }

    return true;
  };

  const onSaveClick = () => {
    const data = {
      eventGbsCode: event.eventGbsCode,
      businessUnitId: event.businessUnitId,
      multilingual: event.multilingual,
      isGbsRegistrationEnabled: event.isGbsRegistrationEnabled,
      isGbsSalesEnabled: event.isGbsSalesEnabled,
      isParticipationEvent: event.isParticipationEvent,
      isMatchmakingEvent: event.isMatchmakingEvent,
      rXEventCode: event.rXEventCode,
      eventCycle: event.eventCycle,
      eventType: event.eventType,
      top100: event.top100,
      businessRelationship: event.businessRelationship,
      isJointVenture: event.isJointVenture,
      isAnonymousRegistrationEnabled: event.isAnonymousRegistrationEnabled
    };

    if (!isFormDataValid(data)) {
      return;
    }

    setStatus({
      ...status,
      isSaving: true
    });

    EventService.setEvent(baseApiUrl, event.id, data, eventFields, interfaceLocale)
      .then((result) => {
        const hasErrors = Utils.serviceResultHasError(result);
        setEvent({
          ...event,
          ...result,
          errors: Utils.constructErrorsFromServiceResult(result, translations.errorMessages)
        });
        setStatus({
          isEditing: hasErrors,
          isSaving: false,
          savedSucceeded: !hasErrors
        });

        if (!hasErrors && props.onSaved) {
          props.onSaved(result);
        }
      });
  };

  const onEditClick = () => {
    setStatus({
      isEditing: true
    });
  };

  const onUpdateField = (dataEvent) => {
    setEvent(prevEvent => ({
      ...prevEvent,
      ...dataEvent
    }));
  };

  const onHide = () => {
    // revert to the loading state
    setStatus({
      isLoading: true
    });

    if (props.onHide) {
      props.onHide();
    }
  };

  let modalBody = React.Component;
  if (status.isLoading) {
    modalBody = <BigCircleSpinner />;
  } else if (!event.id || !event.supportedInPlatform) {
    modalBody = <ErrorContainer errors={[translations.errorMessages.event_edition_not_atlas]} />;
  } else {
    modalBody = (
      <React.Fragment>
        {
          status.savedSucceeded ? <SuccessContainer messages={[translations.saveConfirmationMessage]} /> : null
        }
        <ShowSettingsSection
          {...event}
          translations={translations}
          isEditing={status.isEditing}
          isSaving={status.isSaving}
          onEditClick={onEditClick}
          onCancelClick={onCancelClick}
          onSaveClick={onSaveClick}
          onUpdateField={onUpdateField}
          navigation={props.navigation}
          isRxAdmin={props.isRxAdmin}
          tags={props.tags}
        />
      </React.Fragment>
    );
  }

  return (
    <Modal show={show} onHide={onHide} dialogClassName="show-settings" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <div>{translations.showSettingsTitle}</div>
          {
          featureToggles.isFeatureEnabled(featureToggles.Keys.IS_MODAL_UPDATE_DESCRIPTION_ENABLED)?
          <HelpBlock className='help-block'>
            {StringFormatter.format(translations.showSettingsDescription, eventName)}
            <br /><br />
            {StringFormatter.icon(translations.showSettingsNote, 'fa fa-pencil fa-lg')}
          </HelpBlock>:
          <HelpBlock>
          {StringFormatter.format(translations.showSettingsDescription, eventName)}
          </HelpBlock>
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {modalBody}
      </Modal.Body>
    </Modal>
  );
};

ModalShowSettings.propTypes = {
  eventId: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onSaved: PropTypes.func,
  translations: PropTypes.object.isRequired,
  baseApiUrl: PropTypes.string.isRequired,
  interfaceLocale: PropTypes.string.isRequired,
  tags: PropTypes.object.isRequired,
  isRxAdmin: PropTypes.bool,
  navigation: PropTypes.object.isRequired
};

export default ModalShowSettings;
