import React from 'react';
import { createRoot } from 'react-dom/client';
import EventEditionList from '../../src/js/eventEditionList/EventEditionList';
import '../../src/styles/treeview.less';
import { authController } from '@reedexpo/authorization-component-ui';

export default EventEditionList;

if (global.window && global.window.document) {
  const { props, elementId } = window.reactSettings;
  const authSettings = window.authSettings;
  if (authSettings && authSettings.usePkce) {
    authController.initialize(authSettings);
    authController.login(window.location.href).then(()=>renderComponent(props, elementId));
  } else {
    renderComponent(props, elementId);
  }
}

function renderComponent(props,elementId){
  const container = document.getElementById(elementId);
  const root = createRoot(container);
  root.render(<EventEditionList {...props} />);
}