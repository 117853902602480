import sortBy from 'lodash/sortBy';
import Request from '../../common/api/request';
import ServiceResultFactory from '../../common/api/ServiceResultFactory';

const eventsUrl = baseUrl => `${baseUrl}/v1/events`;
const eventEditionUrl = (baseUrl, eventEditionId) => `${baseUrl}/v1/event-editions/${eventEditionId}`;
const eventEditionsUrl = baseUrl => `${baseUrl}/v1/event-editions`;
const eventEditionsByEventIdUrl = (baseUrl, eventId) => `${baseUrl}/v1/events/${eventId}/event-editions`;
const businessUnitsUrl = baseUrl => `${baseUrl}/v1/business-units`;

const sortByName = (a, b) => {
  const keyA = a.name;
  const keyB = b.name;
  if (keyA < keyB) return -1;
  if (keyA > keyB) return 1;
  return 0;
};

const sortByEventEditionDate = (a, b) => {
  const keyA = new Date(a.startDate);
  const keyB = new Date(b.startDate);
  if (keyA < keyB) return 1;
  if (keyA > keyB) return -1;
  return 0;
};

const getEvents = (baseUrl, locale) => {
  const url = eventsUrl(baseUrl);
  return Request.get(url)
    .then((response) => {
      const successResult = ServiceResultFactory.fromSuccess(response.body);
      return Object.assign(
        successResult,
        {
          events: sortBy(successResult.events, event => event.multilingualDto[0].name.toLowerCase())
        }
      );
    })
    .catch(error => ServiceResultFactory.fromError(locale, error));
};

const getEventEdition = (baseUrl, locale, eventEditionId) => {
  const url = eventEditionUrl(baseUrl, eventEditionId);
  return Request.get(url)
    .then(response => ServiceResultFactory.fromSuccess(response.body))
    .catch(error => ServiceResultFactory.fromError(locale, error));
};

const getEventEditions = (baseUrl, locale) => {
  const url = eventEditionsUrl(baseUrl);
  return Request.get(url)
    .then((response) => {
      const successResult = ServiceResultFactory.fromSuccess(response.body);
      successResult.eventEditions.sort(sortByEventEditionDate);
      return successResult;
    })
    .catch(error => ServiceResultFactory.fromError(locale, error));
};

const getEventEditionsByEventId = (baseUrl, locale, eventId) => {
  const url = eventEditionsByEventIdUrl(baseUrl, eventId);
  return Request.get(url)
    .then((response) => {
      const successResult = ServiceResultFactory.fromSuccess(response.body);
      successResult.eventEditions.sort(sortByEventEditionDate);
      return successResult;
    })
    .catch(error => ServiceResultFactory.fromError(locale, error));
};

const getBusinessUnits = (baseUrl, locale) => {
  const url = businessUnitsUrl(baseUrl);
  return Request.get(url)
    .then((response) => {
      const sortedBUs = response.body.sort(sortByName);
      return ServiceResultFactory.fromSuccess(sortedBUs);
    })
    .catch(error => ServiceResultFactory.fromError(locale, error));
};

export default {
  getEvents,
  getEventEdition,
  getEventEditions,
  getEventEditionsByEventId,
  getBusinessUnits
};
