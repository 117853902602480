/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

class TreeView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: props.defaultcollapsed
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(...args) {
    this.setState({ collapsed: !this.state.collapsed });
    if (this.props.onClick) {
      this.props.onClick(...args);
    }
  }

  render() {
    const {
      collapsed = this.state.collapsed,
      className = '',
      itemClassName = '',
      treeViewClassName = '',
      childrenClassName = '',
      nodeLabel,
      children,
      defaultcollapsed,
      ...rest
    } = this.props;

    let arrowClassName = 'tree-view_arrow';
    let containerClassName = 'tree-view_children';
    if (collapsed) {
      arrowClassName += ' tree-view_arrow-collapsed';
      containerClassName += ' tree-view_children-collapsed';
    }

    const treeViewItem = (
      <div
        className={`tree-view_item ${itemClassName}`}
        onClick={this.handleClick}
      >
        <div
          {...rest}
          className={`${className} ${arrowClassName}`}
        />
        {nodeLabel}
      </div>
    );

    return (
      <div className={`tree-view ${treeViewClassName}`}>
        {treeViewItem}
        <div className={`${containerClassName} ${childrenClassName}`}>
          {collapsed ? null : children}
        </div>
      </div>
    );
  }
}

TreeView.propTypes = {
  collapsed: PropTypes.bool,
  defaultcollapsed: PropTypes.bool,
  nodeLabel: PropTypes.node.isRequired,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  childrenClassName: PropTypes.string,
  treeViewClassName: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node
};

export default TreeView;
