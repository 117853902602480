import React from 'react';
import PropTypes from 'prop-types';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import classnames from 'classnames';
import Classes from '../Classes';

const isEmpty = content => !content;

const getClassNames = (className, onClick) => {
  const clickableClass = onClick ? Classes.formGroupViewModeClickable : '';
  return classnames([Classes.formGroupViewMode, clickableClass, className, Classes.wrapWord]);
};

const showAsLink = (content, tag) => (
  content ? (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={content}
      data-dtm={tag}
    >
      {content}
    </a>
  ) : <p />
);

const ShowAsPhoneNumberLink = (content, tag) => (
  content ? (
    <a
      href={`tel:${content}`}
      data-dtm={tag}
    >
      {content}
    </a>
  ) : <p />
);
const showAsPara = (content, placeholder) => <p>{content || placeholder}</p>;

const FormFieldViewMode = ({
  onEmptyClick,
  tagWhenEmpty,
  tagWhenPopulated,
  tagWhenIsUrl,
  title,
  helpText,
  content,
  placeholder,
  className,
  isUrl,
  isPhoneNumber,
  iconClasses
}) => {
  const onClick = isEmpty(content) ? onEmptyClick : null;

  return (
    <div
      onClick={onClick}
      data-dtm={onClick ? tagWhenEmpty : tagWhenPopulated}
      className={getClassNames(className, onClick)}
      role="presentation"
    >
      {iconClasses ?
        <i className={classnames(iconClasses, Classes.inlineIcon)} />
        : null}
      <h4 className={Classes.wrapWord}>{title}</h4>
      {helpText ? <HelpBlock>{helpText}</HelpBlock> : null}
      {
        // eslint-disable-next-line no-nested-ternary
        isPhoneNumber ?
          ShowAsPhoneNumberLink(content) : (
        isUrl
          ? showAsLink(content, tagWhenIsUrl)
          : showAsPara(content, placeholder))
      }
    </div>
  );
};

FormFieldViewMode.propTypes = {
  onEmptyClick: PropTypes.func,
  tagWhenEmpty: PropTypes.string,
  tagWhenPopulated: PropTypes.string,
  tagWhenIsUrl: PropTypes.string,
  title: PropTypes.string,
  helpText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isUrl: PropTypes.bool,
  isPhoneNumber: PropTypes.bool,
  iconClasses: PropTypes.array
};

export default FormFieldViewMode;
